import React, { ReactElement } from 'react';
import { Flex, Box, useThemeUI } from 'theme-ui';
import LogoSlider from './components/LogoSlider';
import { LogoBeltTemplateProps } from './types';

const LogoBeltTemplate = ({
  title,
  logos,
}: LogoBeltTemplateProps): ReactElement => {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Box
        sx={{
          fontWeight: [700, 800],
          fontSize: ['17px', '22px'],
          lineHeight: ['21px', '27px'],
          textAlign: 'center',
          color: '#666666',
        }}
      >
        {title}
      </Box>
      <LogoSlider logos={logos} />
    </Flex>
  );
};

export default LogoBeltTemplate;
