import { ThemeUICSSObject } from "theme-ui";

export const SLIDER_STYLES: ThemeUICSSObject = {
    height: '100%',
    padding: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    maxWidth: '80%',

    '.slick-list': {
        overflow: 'hidden',
    },

    '.slick-slider': {
        minWidth: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },

    '.slider': {
        position: 'relative',
        padding: '0px 20px',
    },

    '.slick-track': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.slick-prev': {
        top: '50%',
        '&::before': {
            content: 'none',
        },
        'svg > path': {
            fill: '#ccc',
        },
    },
    '.slick-next': {
        top: '50%',
        '&::before': {
            content: 'none',
        },
        'svg > path': {
            fill: '#ccc',
        },
    },
};